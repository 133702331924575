//Apollo setup
import VueApollo from 'vue-apollo'
import { apiBaseUrl } from '../constant/connection'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { createUploadLink } from 'apollo-upload-client'

const defaultOptions = {
  watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
  },
  query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
  },
}

const defaultClient = new ApolloClient({
  link: createUploadLink({
    uri:apiBaseUrl,
  }),
  cache: new InMemoryCache(),
  defaultOptions : defaultOptions
});

const apolloProvider = new VueApollo({
  defaultClient : defaultClient,
});

export default apolloProvider

