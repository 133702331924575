import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class ItemService {
    readItemQuery(){
        var query = `query($paging:ServerPaging, $status:String){
                        GetProductMaster(Paging:$paging, activeFlag:$status){
                            ProductMaster{
                                item_id
                                product_code
                                item_name
                                barcode
                                active_flag
                                item_type
                                type_detail_id
                                base_uom
                                base_weight
                                details{
                                    item_id
                                    uom_conv
                                    conv_amount
                                    is_secondary
                                }
                            }
                            Total
                        }
                    }`;
        return query;
    }

    async getItemDetail(id){        
        const variables = {
            id : id
        }
        var query = gql`
            query($id:String){
                GetProductMaster(itemID:$id){
                    ProductMaster{
                        details{
                            item_id
                            uom_conv
                            conv_amount
                            is_secondary
                        }
                    }
                    Total
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        return result.data.GetProductMaster.ProductMaster[0].details;
    }


    async getItem(variables){
        var query = gql`query($page:Pagination,$filter:String){
            GetProductMaster(Page:$page,filter:$filter){
                ProductMaster{
                    item_id
                    item_name
                    barcode
                    active_flag
                    item_type
                    type_detail_id
                    base_uom
                    base_weight
                    details{
                        item_id
                        uom_conv
                        conv_amount
                        is_secondary
                    }
                }
                Total
            }
        }`;
        
        return await globalfunc.defaultApolloQueryDefault(query,variables)
    }

    async addQuery(variables){
        var query = gql`mutation($data:NewItem!){
            CreateItem(input:$data){
              response
            }
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async deleteQuery(variables){
        var query = gql`mutation($id:String!){
            DeleteItem(ItemId:$id){
                response
            }
        }`;

        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`mutation($id:String!,$data:NewItem!){
            UpdateItem(ItemId:$id,New:$data){
              response
            }
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async getTypeDetailQuery(){
        var query = gql`query{
            GetTypeDetail(Flags:["All","Project","Retail"]){
                type_detail_id
                type_detail_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        
        var typeDetailData = [];
        for (let i = 0; i < result.data.GetTypeDetail.length; i++) {
            var label = result.data.GetTypeDetail[i].type_detail_name;
            var str = {value:result.data.GetTypeDetail[i].type_detail_id, label:label}
            typeDetailData.push(str);
        }
        return typeDetailData
    }
    
}

export default new ItemService();