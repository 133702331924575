import { response } from '../constant/response';
import moment from 'moment';

export const uiSetting = {
    // footerText : "TESTING NEW FRONT-END",
    // userImage : require("../../../public/img/avatars/"),
    sidebarImage : require("../../../public/img/avatars/westpex-full-logo.png"),
    sidebarShrinkImage : require("../../../public/img/avatars/westpex-logo.png")
}

export const dashboard = {
    card_color : [
        { 
            primary : '#E74C3C',
            secondary : '#CB4335'
        },
        { 
            primary : '#E67E22',
            secondary : '#CA6F1E'
        },
        { 
            primary : '#17A589',
            secondary : '#17A589'
        },
        { 
            primary : '#6495ED',
            secondary : '#4169E1'
        }
    ],
}

export const global_variable = {
    max_error_focus : 1
}  

export const production = {
    internal : 1,
    external : 2,
    send : 1,
    receive : 2,
}                

export const trial_balance = {
    status : "Approved"
}  

export const project = {
    gudang_cikupa : "STORAGE001",
}      

export const invoice = {
    all_status : ["New", "Sent"],
} 

export const report = {
    default_date : "This Month",
    default_start_date : moment().startOf('month').format('YYYY-MM-DD'),
    default_end_date : moment().endOf('month').format('YYYY-MM-DD')
}

export const datetime_zone = {
    date_from : " " + moment().startOf('day').format('HH:mm:ss').toString(),
    date_to : " " + moment().endOf('day').format('HH:mm:ss').toString()
}

export const kendo_grid = {
    default_operator: {
        string: {
            contains: "Contains",
            isnotempty: "Is not empty",
            isempty: "Is empty",
        },
        number: {
            lt: "Is less than",
            eq: "Is equal to",
            gt: "Is greater than",
            lte: "Less than or equal to",
            gte: "Greater than or equal to"
        },
        date: {
            gtd: "Date From",
            ltd: "Date To",
        },
        dropdown: {
            eq: "Is equal to",
        }
    },
    client_operator: {
        string: {
            contains: "Contains",
            isnotempty: "Is not empty",
            isempty: "Is empty",
        },
        number: {
            lt: "Is less than",
            eq: "Is equal to",
            gt: "Is greater than",
            lte: "Less than or equal to",
            gte: "Greater than or equal to"
        },
        date: {
            gte: "Date From",
            lte: "Date To",
        },
        dropdown: {
            eq: "Is equal to",
        }
    },
    default_pageable_config: {
        pageSizes: [5, 10, 20, 50, 100, 200, 500, 1000, 2000],
        buttonCount: 5
    },
    default_grid_pagesize: 10,
    default_form_grid_pagesize: 20,
    default_detail_grid_pagesize: 5,
    default_sort_grid: {
        mode: "single",
        allowUnsort: true,
    },
    multiple_sort_grid: {
        mode: "mixed",
        allowUnsort: true,
        showIndexes: true
    }
}

export const sweet_alert = {
    success_save_print: {
        icon: 'success',
        title: "Info",
        title: response.successSavePrint,
        showDenyButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Print PDF',
        denyButtonText: 'Print HTML',
        confirmButtonColor: '#321fdb',
        denyButtonColor: '#321fdb',
    },
    update_save_print: {
        icon: 'success',
        title: "Info",
        text: response.successUpdatePrint,
        showDenyButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Print PDF',
        denyButtonText: 'Print HTML',
        confirmButtonColor: '#321fdb',
        denyButtonColor: '#321fdb',
    },
    delete: {
        title: "Confirmation",
        text: response.deleteAlert,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        dangerMode: true
    },
    print: {
        title: response.printAlert,
        showDenyButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Print PDF',
        denyButtonText: 'Print HTML',
        confirmButtonColor: '#321fdb',
        denyButtonColor: '#321fdb',
    },
    approve: {
        title: "Confirmation",
        text: response.approveAlert,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        dangerMode: true
    },
    update_status: {
        title: "Confirmation",
        text: response.updateStatusAlert,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        dangerMode: true
    },
    update_inline_grid: {
        title: "Confirmation",
        text: response.updateAlert,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        dangerMode: true
    },
    print_proforma_invoice: {
        title: response.proformaInvoiceAlert,
        showDenyButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Normal',
        denyButtonText: 'Booking',
        confirmButtonColor: '#321fdb',
        denyButtonColor: '#321fdb',
    },
}

export const project_type = [
    {label : "Project", value : 1},
    {label : "Retail", value : 2},
]  

export const role_flag_name = {
    peminjamanAlat_Approver : 'Approver Peminjaman Alat',
    estimasiKomisi_Approver : 'Approver Estimasi Komisi'
}  