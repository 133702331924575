import 'core-js/stable';

import Vue from 'vue';
import App from './App';
import router from './router';
import CoreuiVue from '@coreui/vue';
import store from './store';
import VueApollo from 'vue-apollo';
import VueCookies from 'vue-cookies';
import apolloProvider from './infrastructure/helper/apolloclient';
import { iconsSet as icons } from './assets/icons/icons.js';
import { globalfunc } from './shared/GlobalFunction.js';
import { global_variable } from './infrastructure/constant/variable.js';

//kendo UI
import '@progress/kendo-ui';
import '@progress/kendo-theme-default/dist/all.css';

//loader
import VueLoading from 'vuejs-loading-plugin';

//async await 
import "core-js/stable"; 
import "regenerator-runtime/runtime";

//Sweet Alert 2 
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

//Select2 Vue
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'

//excel xlxs
import VueExcelXlsx from "vue-excel-xlsx";

//Numeral Filter Formating
import vueNumeralFilterInstaller from 'vue-numeral-filter';

const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};

Vue.config.performance = true
Vue.use(CoreuiVue);
Vue.use(VueSweetalert2,options);
Vue.prototype.$log = console.log.bind(console)
Vue.prototype.$globalfunc = globalfunc;
Vue.prototype.$globalvariable = global_variable;
Vue.use(VueApollo);
Vue.use(VueCookies);
Vue.use(VueLoading);
Vue.use(VueExcelXlsx);
Vue.component('v-select', vSelect);
Vue.use(vueNumeralFilterInstaller, { locale: 'en-gb' });

new Vue({
  el: '#app',
  apolloProvider, // add option
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
