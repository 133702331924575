export const response = {
    successSave : "Data telah berhasil disimpan",
    successDelete : "Data telah terhapus",
    successUpdate : "Data telah berhasil diupdate",
    successApprove : "Data telah disetujui",
    fieldRequired : "kolom ini tidak boleh kosong",
    fieldRequiredNotMinus : "kolom ini tidak boleh minus",
    deleteAlert : "Apakah anda yakin ingin menghapus data ini?",
    updateStatusAlert : "Apakah anda yakin ingin mengubah status data ini?",
    noRecord: "Data tidak ditemukan",
    printAlert: "Pilihan Print",
    proformaInvoiceAlert: "Pilihan Print Proforma Invoice",
    successSavePrint : "Data telah berhasil disimpan. Klik tombol Print untuk mencetak",
    successUpdatePrint : "Data telah berhasil diupdate. Klik tombol Print untuk mencetak",
    approveAlert : "Apakah anda yakin ingin menyetujui data ini?",
    updateAlert : "Apakah anda yakin ingin mengubah data ini?",
    printStatusAlert : "Print hanya dapat dilakukan hanya pada status Proses atau Finish",
}                
